import React, { useCallback, useMemo } from 'react';

import { Button, Stack, Tooltip } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { droppedMsg, sortListAlphabetical } from './Helper';
import { useUserData } from '../../App/Component/UserDataProvider';
import CustomModal from '../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../RiverusUI/Components/SelectComponent';
import {
  fetchAllContractCategory,
  fetchRequestApprovals,
} from '../../Services/Approval';
import { editDraftData, getDraftById } from '../../Services/Draft';
import { draftStatus } from '../State/DraftState';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  draftId: GridRowId;
}

const EditDraftCategory: React.FC<Props> = ({ open, onClose, draftId }) => {
  const { data: draftData } = useQuery({
    queryKey: ['edit_get_draft_by_id', draftId],
    queryFn: async () => {
      const response = await getDraftById(draftId as string);
      return response as any;
    },
    enabled: !!draftId,
  });

  const { data: contractCategory, isLoading: categoryLoading } = useQuery({
    queryKey: ['contract_category_data'],
    queryFn: fetchAllContractCategory,
    select: (response: any) => response.results,
  });

  const { user_id, userIsCreator } = useUserData();

  const isOwner = useMemo(() => {
    const index = draftData?.owners?.findIndex(
      (owner: any) => owner?.id === user_id
    );
    if (index > -1) {
      return true;
    }
    return false;
  }, [draftData, user_id]);

  const methods = useForm();
  const { handleSubmit, control, reset } = methods;

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { data: creatorCategory } = useQuery({
    queryKey: [
      'edit_contract_category_has_policy',
      draftData?.contractType?.id,
    ],
    queryFn: () => {
      const params = `?approval_type=contract_request&contract=${draftData?.contractType?.id}`;
      return fetchRequestApprovals(params);
    },
    select: (response: any) => {
      const filterCategory = response?.results
        ?.map((item: any) => {
          const categoryItem = item?.contract_category?.filter(
            (category: any) =>
              category?.contract_types?.some(
                (type: any) => type?.id === draftData?.contractType?.id
              )
          );
          return categoryItem;
        })
        .flat();
      const isCategoryRequired = response?.results?.filter(
        (item: any) => !item?.contract_category?.length
      );
      return {
        isCategoryRequired: isCategoryRequired?.length ? false : true,
        creatorCategoryData: filterCategory,
      };
    },
    enabled: !!(draftData?.contractType?.id && userIsCreator),
  });

  const filteredContractCategory = useMemo(() => {
    return contractCategory?.filter((category: any) =>
      category?.contract_types?.some(
        (type: any) => type?.id === draftData?.contractType?.id
      )
    );
  }, [contractCategory, draftData?.contractType?.id]);

  const noContractTypeCategory = useMemo(() => {
    return contractCategory?.filter(
      (category: any) => category?.contract_types?.length === 0
    );
  }, [contractCategory]);

  const combinedContractCategories = useMemo(() => {
    return [
      ...(creatorCategory?.creatorCategoryData || []),
      ...(noContractTypeCategory || []),
      ...(filteredContractCategory || []),
    ];
  }, [
    filteredContractCategory,
    noContractTypeCategory,
    creatorCategory?.creatorCategoryData,
  ]);

  const { mutate: updateDraftData } = useMutation({
    mutationKey: ['edit-category'],
    mutationFn: editDraftData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
      enqueueSnackbar('Successfully updated category!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update category', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = useCallback(
    (data: any) => {
      if (data?.contract_category) {
        const payload = {
          id: draftId,
          body: {
            id: draftId,
            ...data,
          },
        };
        updateDraftData(payload);
      }
    },
    [draftId, updateDraftData]
  );

  React.useEffect(() => {
    reset({
      contract_category: draftData?.contract_category?.id || [],
    });
  }, [draftData, reset]);

  return (
    <CustomModal
      maxWidth="md"
      title="Edit Category"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: '500px', padding: '30px 10px' }}>
            <RISelectComponent
              name="contract_category"
              control={control}
              label="Contract Category"
              options={
                userIsCreator
                  ? sortListAlphabetical(creatorCategory?.creatorCategoryData)
                  : sortListAlphabetical(combinedContractCategories)
              }
              loading={categoryLoading}
            />
            <Tooltip
              title={droppedMsg}
              arrow
              disableHoverListener={
                draftData?.status !== draftStatus?.Draft_Dropped
              }
            >
              <Stack>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={
                    !isOwner || draftData?.status === draftStatus?.Draft_Dropped
                  }
                >
                  Submit
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default EditDraftCategory;
