/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useState } from 'react';

import { Close } from '@mui/icons-material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, Chip, Stack, Switch, Typography } from '@mui/material';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import { getPara } from '../../DocumentView/Component/ClauseComponent/utils/ClauseTypeUtils';
import CustomModal from '../../RiverusUI/Components/CustomModal';

interface IProps {
  page: string;
  setView: any;
  clauseTypeData: any;
  setClauseTypeData: any;
  isButtonEnabled?: boolean;
  totalCount: number;
  filteredData: any;
}

const DownloadExcel: FC<IProps> = (props) => {
  const {
    page,
    setView,
    clauseTypeData,
    setClauseTypeData,
    isButtonEnabled,
    filteredData,
    totalCount,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const tableRef = useRef(null);

  const handleSwitch = (value: boolean, index: number) => {
    const clauses = [...clauseTypeData];
    clauses[index].checked = value;
    setClauseTypeData(clauses);
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<SaveAltIcon />}
        onClick={() => {
          setOpen(true);
        }}
      >
        Download results
      </Button>
      {open && (
        <CustomModal
          maxWidth="md"
          title="Download Results"
          open={open}
          handleClose={() => setOpen(false)}
        >
          <Stack spacing={2} sx={{ minWidth: '500px' }} mt={2}>
            <Typography fontWeight={600}>
              Include clause texts from the selected clauses
            </Typography>
            <Stack
              spacing={2}
              sx={{ maxHeight: '60vh', overflow: 'auto' }}
              paddingRight={1}
            >
              {clauseTypeData.map((clause: any, index: number) => (
                <Stack
                  key={index}
                  direction="row"
                  justifyContent="space-between"
                  sx={{ background: '#88305F1F' }}
                  padding="10px 13px"
                  borderRadius="5px"
                  alignItems="center"
                  marginLeft={clause.parent ? 0 : '20px !important'}
                >
                  <Typography>{clause.display_name}</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Chip
                      sx={{
                        background: '#FFECF1',
                        padding: '5px 7px',
                        borderRadius: '5px',
                        color: '#6D264C',
                      }}
                      label={`${clause.count} / ${totalCount}`}
                    />
                    <Switch
                      checked={clause.checked}
                      onChange={(event) =>
                        handleSwitch(event.target.checked, index)
                      }
                    />
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack direction="row">
              <Button
                variant="contained"
                disabled={!isButtonEnabled}
                onClick={() => {
                  setView('table');
                  setOpen(false);
                }}
              >
                Show in App
              </Button>
              <DownloadTableExcel
                filename={`${page}-result-${Date.now()}`}
                sheet={`${page}-result`}
                currentTableRef={tableRef.current}
              >
                <Button variant="contained" disabled={!isButtonEnabled}>
                  Export
                </Button>
              </DownloadTableExcel>
              <Button
                variant="outlined"
                startIcon={<Close />}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </CustomModal>
      )}
      {clauseTypeData && (
        <TableExcel
          clauseTypeData={clauseTypeData}
          tableRef={tableRef}
          page={page}
          filteredData={filteredData}
        />
      )}
    </>
  );
};

const TableExcel = (props: {
  clauseTypeData: any;
  page: string;
  tableRef: any;
  filteredData: any;
}) => {
  const { clauseTypeData, page, tableRef, filteredData } = props;
  return (
    <table
      id={`table-to-xls-${page}-results`}
      style={{ display: 'none', fontFamily: 'Cambria Math' }}
      ref={tableRef}
    >
      <thead>
        <tr style={{ background: '#77b8fd' }}>
          <th style={{ fontWeight: 600 }} align="center">
            File Name
          </th>
          {clauseTypeData
            .filter((data: any) => data.checked)
            ?.map((clause: any, index: number) => (
              <th style={{ fontWeight: 600 }} key={`excel-clause-${index}`}>
                {clause.display_name}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {filteredData.map((file: any, index: number) => (
          <tr key={`excel-file-${index}`}>
            <th style={{ fontWeight: 600, verticalAlign: 'top' }}>
              {file.file_name}
            </th>
            {clauseTypeData
              .filter((data: any) => data.checked)
              ?.map((clause: any, index: number) => (
                <td key={`excel-file-data-${index}`} valign="top">
                  <ol type="A">
                    {file?.clauses
                      ?.find((item: any) => item.clause_name === clause.name)
                      ?.para?.map((para: any, paraIndex: number) => (
                        <li
                          key={`para-${paraIndex}`}
                          dangerouslySetInnerHTML={{
                            __html:
                              para?.[clause.key] ||
                              getPara(
                                file?.extracted.raw_content,
                                clause?.[file.id]?.[0].para_id,
                                clause?.[file.id]?.[0].sentence_id,
                                clause?.[file.id]?.[0].table &&
                                  clause?.[file.id]?.[0]?.table[0]?.row,
                                clause?.[file.id]?.[0].table &&
                                  clause?.[file.id]?.[0]?.table[0]?.column
                              ),
                          }}
                        />
                      ))}
                  </ol>
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DownloadExcel;
