import { authApi } from './authApi';

export const fetchNotifications = async (params = '') => {
  return await authApi.get(`common-notification/${params}`);
};

export const postMarkAllRead = async (payload: any) => {
  return await authApi.post('common-notification/', payload);
};

export const fetchSnoozeNotification = async (id = ''): Promise<any> => {
  return await authApi.get(`/common-notification/${id}/snooze/`);
};

export const fetchDontRemindAgain = async (id = ''): Promise<any> => {
  return await authApi.get(`/common-notification/${id}/dont_remind/`);
};
