import React, { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import DatePickerElement from '../../../RiverusUI/Components/ControlledDatePicker';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { createReminder } from '../../../Services/DocumentLibrary';
import {
  editDraftData,
  getDraftById,
  requestApproval,
} from '../../../Services/Draft';
import { formatPayloadDate } from '../Helper';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  approversList: any[];
  cardData: any;
  priority: any[];
  combinedApproversIds: any[];
}

const RequestApproval: React.FC<Props> = ({
  open,
  onClose,
  approversList,
  cardData,
  priority,
  combinedApproversIds,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm();
  const queryClient = useQueryClient();
  const { id } = useParams<{
    id: string;
  }>();

  const selectedApprovers = watch('approvers');

  useEffect(() => {
    if (priority?.length) {
      setValue('approvers', priority);
    }
  }, [priority]);

  const approversData = approversList?.map((data: any) => ({
    ...data,
    name: data.first_name + ' ' + data.last_name,
  }));

  const { mutate: updateDraftData } = useMutation({
    mutationFn: editDraftData,
    onSuccess: () => {
      enqueueSnackbar('Successfully updated draft data!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: request_approval, isPending: loadingApproval } = useMutation({
    mutationKey: ['request_approval'],
    mutationFn: requestApproval,
    onSuccess: () => {
      const combinedApprovers = [...selectedApprovers, ...combinedApproversIds];
      const uniqueApprovers = Array.from(new Set(combinedApprovers));

      const payload = {
        id: id,
        body: {
          approvers: uniqueApprovers,
        },
      };
      updateDraftData(payload);

      queryClient.invalidateQueries({
        queryKey: ['fetch_Kdp_deviations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      onClose();
    },
  });

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: draftData } = useQuery({
    queryKey: ['get_draft_data_by_id', id],
    queryFn: () => getDraftById(id as string),
    enabled: !!id,
  });

  const onSubmit = (data: any) => {
    const { condition, draft, id } = cardData;
    let priority = 0;
    const approversArray = data?.approvers?.map((approver: string) => {
      priority = priority + 1;
      return {
        id: approver,
        priority: priority,
      };
    });
    const payload = {
      approvers: approversArray,
      reason: data?.reason,
      approval_sequence: condition?.approval_sequence,
      deviation: id,
      draft: draft,
    };
    request_approval(payload);

    approversArray?.forEach((approver: any) => {
      const reminderPayload: any = {
        users: [approver?.id],
        draft: draft,
        reminder_type:
          draftData?.approval_type === 'contract_condition' ||
          (draftData?.approval_type === 'pre_signatory_approval' &&
            !draftData?.pre_signatories)
            ? 'deviation'
            : 'approval',
        deviation_name: 'Deviation',
        name:
          draftData?.approval_type === 'contract_condition' ||
          (draftData?.approval_type === 'pre_signatory_approval' &&
            !draftData?.pre_signatories)
            ? `${draftData?.contractName} - Conditional Approval`
            : draftData?.approval_type === 'contract_request'
              ? `${draftData?.contractName} - Requisition Approval`
              : draftData?.contractName,
        deviation: id,
        app: 'Stylus',
        due_date: data?.due_date
          ? formatPayloadDate(new Date(data?.due_date))
          : '',
      };
      createReminderMutation(reminderPayload);
    });
  };

  return (
    <CustomModal
      title="Policy Approval Request"
      open={open}
      handleClose={onClose}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} marginY="15px" sx={{ width: '600px' }}>
          <Typography>Request approval for this Issue from:</Typography>
          <RISelectComponent
            isShowDropDown={true}
            readOnly={true}
            name="approvers"
            control={control}
            label="Select approvers"
            options={approversData}
            isMultiselect={true}
            renderCustomComponent={(value: any, props) => (
              <ShareChip key={value?.id} {...props} label={value?.name} />
            )}
          />
          <Typography>Reason for approval</Typography>
          <ControlledTextField
            name="reason"
            control={control}
            required
            fullWidth
          />
          <Stack spacing={2}>
            <Typography fontSize="14px" fontWeight="500" pb="6px" color="#000">
              Set a due date for the approval:
            </Typography>
            <DatePickerElement
              name="due_date"
              control={control}
              label="Due Date"
              disablePast
              required
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!selectedApprovers?.length}
              loading={loadingApproval}
            >
              Yes, Request Approval
            </LoadingButton>
            <Button variant="outlined" onClick={onClose}>
              No, Go Back
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default RequestApproval;
