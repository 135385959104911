import React, { useEffect, useMemo } from 'react';

import { Box, Button, Stack } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchComponent from './SearchComponent';
import UserInfo from './userInfo';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import WebLogoIcon from '../../../RiverusUI/Components/Icons/WebLogoIcon';
import NotificationComponent from '../../Notification/Component/NotificationComponent';

interface IProps {
  platform: string;
  gotoUmbrella: VoidFunction;
  gotoStyllus: VoidFunction;
  gotoReminder: VoidFunction;
  gotoAdmin: VoidFunction;
}

const navbarList = [
  {
    name: 'STYLLUS',
    displayName: 'Stylus',
  },
  {
    name: 'UMBRELLA',
    displayName: 'Umbrella',
  },
  {
    name: 'REMINDER',
    displayName: 'Reminder',
  },
];

const Toolbar: React.FC<IProps> = (props) => {
  const { gotoUmbrella, gotoStyllus, gotoReminder, platform, gotoAdmin } =
    props;

  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  const profile = useMemo(
    () => getSessionStorage('user_profile'),
    [getSessionStorage('user_profile')]
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      pathname === '/templates' ||
      pathname === '/draftingreview' ||
      pathname === '/approvals'
    ) {
      gotoStyllus();
    }
    if (pathname === '/documentlibrary' || pathname === '/filter') {
      gotoUmbrella();
    }
    if (pathname === '/reminders') {
      gotoReminder();
    }
    if (pathname.includes('admin')) {
      gotoAdmin();
    }
  }, [pathname]);

  const handleChange = (newValue: string) => {
    if (newValue === 'STYLLUS') {
      gotoStyllus();
      navigate('/draftingreview');
    }
    if (newValue === 'UMBRELLA' && isLoggedIn) {
      gotoUmbrella();
      navigate('/documentlibrary');
    }
    if (newValue === 'REMINDER' && isLoggedIn) {
      gotoReminder();
      navigate('/reminders');
    }
    if (newValue === 'ADMIN' && isLoggedIn) {
      gotoAdmin();
      navigate('/admin/data-dictionary');
    }
  };

  return (
    <Box className="web-menu-container">
      {isLoggedIn && (
        <Stack
          flex={1}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <WebLogoIcon />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {navbarList?.map((navItem: any, index: number) => {
              const isActive = platform === navItem?.name;
              const background = isActive ? '#e7d6df' : ' transparent';
              return (
                <Button
                  key={`toolbar-${index}`}
                  sx={{
                    color: '#88305F',
                    padding: '4px 12px',
                    textTransform: 'capitalize',
                    borderRadius: '5px',
                    background: background,
                    fontWeight: 700,
                  }}
                  onClick={() => handleChange(navItem?.name)}
                >
                  {navItem?.displayName}
                </Button>
              );
            })}
            {profile?.roles?.includes('admin') && (
              <Button
                sx={{
                  color: '#88305F',
                  padding: '4px 12px',
                  textTransform: 'capitalize',
                  borderRadius: '5px',
                  background: platform === 'ADMIN' ? '#e7d6df' : ' transparent',
                  fontWeight: 700,
                }}
                onClick={() => handleChange('ADMIN')}
              >
                Admin
              </Button>
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <SearchComponent platform={platform} />
            <NotificationComponent />
            <UserInfo />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default Toolbar;
