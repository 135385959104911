export const clausesHaveChildren = [
  'term',
  'termination',
  'renewal',
  'governing_law',
  'payment',
  'indemnity',
  'confidentiality',
  'change_of_control',
  'events_of_default',
  'dispute_resolution',
  'limited_liability',
  'force_majeure',
  'non_compete',
  'non_solicitation',
  'subletting',
  'consent',
  'data_breach',
];

export const childData: Record<string, any> = {
  term: [
    { name: 'start', displayName: 'Start Date', key: 'pattern' },
    { name: 'end', displayName: 'End Date', key: 'pattern' },
    { name: 'duration', displayName: 'Term Duration', key: 'pattern' },
  ],
  termination: [
    { name: 'event', displayName: 'Termination Event', key: 'phrase' },
    { name: 'lockin', displayName: 'Lock-in Period', key: 'sentence' },
  ],
  renewal: [
    { name: 'auto_renewal', displayName: 'Auto- Renewal', key: 'para' },
    {
      name: 'reference_date',
      displayName: 'Notice Reference Date',
      key: 'phrase',
    },
    {
      name: 'renewal_duration',
      displayName: 'Notice Duration',
      key: 'phrase',
    },
    {
      name: 'notice_auto_renewal',
      displayName: 'Notice for Auto- Renewal',
      key: 'phrase',
    },
  ],
  governing_law: [
    { name: 'jurisdiction', displayName: 'Jurisdiction', key: 'jurisdiction' },
  ],
  payment: [
    { name: 'amounts', displayName: 'Amount', key: 'amountString' },
    { name: 'timeline', displayName: 'Timeline', key: 'phrase' },
  ],
  indemnity: [
    { name: 'amounts', displayName: 'Indemnity Amount', key: 'amountString' },
    {
      name: 'bi_1',
      displayName: 'Indemnity/Reimbursements/Costs Extent of Cost',
      key: 'string',
    },
    {
      name: 'bi_2',
      displayName: 'Indemnity/Reimbursements/Costs Triggering Event',
      key: 'string',
    },
    { name: 'transaction', displayName: 'Transaction', key: 'string' },
  ],
  confidentiality: [
    {
      name: 'c_except',
      displayName: 'Exception To Confidentiality',
      key: 'sentence',
    },
    {
      name: 'nature',
      displayName: 'Nature of Confidentiality',
      key: 'nature',
    },
    {
      name: 'duration',
      displayName: 'Duration of Confidentiality',
      key: 'phrase',
    },
  ],
  change_of_control: [
    {
      name: 'definitions',
      displayName: 'Change of Control Definition',
      key: 'para',
    },
    {
      name: 'termination',
      displayName: 'Change of Control Termination',
      key: 'sentence',
    },
    {
      name: 'notice',
      displayName: 'Change of Control Notice',
      key: 'sentence',
    },
    {
      name: 'consent',
      displayName: 'Change of Control Consent',
      key: 'sentence',
    },
    {
      name: 'payment',
      displayName: 'Change of Control Payment',
      key: 'sentence',
    },
  ],
  events_of_default: [
    { name: 'events', displayName: 'Events Of Default Event', key: 'events' },
  ],
  dispute_resolution: [
    {
      name: 'legal_entities',
      displayName: 'Dispute Resolution Act/Statute',
      key: 'capture',
    },
    { name: 'venue', displayName: 'Dispute Resolution Venue', key: 'capture' },
    { name: 'panel', displayName: 'Dispute Resolution Panel', key: 'capture' },
  ],
  limited_liability: [
    { name: 'amount_string', displayName: 'Amount', key: 'amountString' },
    { name: 'amounts', displayName: 'Amount', key: 'amountString' },
  ],
  force_majeure: [
    { name: 'fm_events', displayName: 'Events Covered', key: 'events' },
  ],
  non_compete: [
    { name: 'duration', displayName: 'Non Compete Duration', key: 'phrase' },
    {
      name: 'territory',
      displayName: 'Non Compete Territory',
      key: 'phrase',
    },
  ],
  non_solicitation: [
    {
      name: 'duration',
      displayName: 'Non Solicitation Duration',
      key: 'phrase',
    },
  ],
  subletting: [
    {
      name: 'notice',
      displayName: 'Subletting/Assignment Notice Info',
      key: 'sentence',
    },
    {
      name: 'consent',
      displayName: 'Subletting/Assignment Consent Info',
      key: 'sentence',
    },
  ],
  consent: [
    {
      name: 'consent_regulatory_bi',
      displayName: 'Consent Regulatory',
      key: 'sentence',
    },
  ],
  data_breach: [
    { name: 'notify', displayName: 'Data Breach Notify', key: 'para' },
    {
      name: 'regulation',
      displayName: 'Data Breach Regulation',
      key: 'extraction',
    },
  ],
};
