import React, { ReactNode } from 'react';

import { TextFieldProps } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotsComponentsProps,
} from '@mui/x-date-pickers/DatePicker';
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  Path,
} from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import { useFormError } from './FormErrorProvider';

export type DatePickerElementProps<
  T extends FieldValues,
  TInputDate,
  TDate = TInputDate,
> = Omit<DatePickerProps<TDate>, 'value' | 'slotProps'> & {
  name: Path<T>;
  required?: boolean;
  isDate?: boolean;
  parseError?: (error: FieldError) => ReactNode;
  validation?: ControllerProps<T>['rules'];
  control?: Control<T>;
  inputProps?: TextFieldProps;
  helperText?: TextFieldProps['helperText'];
  textReadOnly?: boolean;
  slotProps?: Omit<DatePickerSlotsComponentsProps<TDate>, 'textField'>;
  onChange?: any;
};

export default function DatePickerElement<TFieldValues extends FieldValues>({
  parseError,
  name,
  required,
  validation = {},
  inputProps,
  control,
  textReadOnly,
  slotProps,
  ...rest
}: DatePickerElementProps<TFieldValues, any, any>): JSX.Element {
  const errorMsgFn = useFormError();
  const customErrorFn = parseError || errorMsgFn;
  if (required && !validation.required) {
    validation.required = 'This field is required';
  }

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      defaultValue={null as any}
      render={({ field, fieldState: { error } }) => {
        if (field?.value && typeof field?.value === 'string') {
          field.value = new Date(field.value) as any; // need to see if this works for all localization adaptors
        }

        return (
          <DatePicker
            {...rest}
            {...field}
            ref={(r) => {
              field.ref(r?.querySelector('input'));
            }}
            onClose={(...args) => {
              field.onBlur();
              if (rest.onClose) {
                rest.onClose(...args);
              }
            }}
            onChange={(v, keyboardInputValue) => {
              const currentDate = new Date();
              currentDate.setHours(0, 0, 0, 0);
              if (v < currentDate) {
                field.onChange(null, keyboardInputValue);
                return;
              }

              field.onChange(v, keyboardInputValue);
              if (typeof rest.onChange === 'function') {
                rest.onChange(v, keyboardInputValue);
              }
            }}
            disablePast
            slotProps={{
              ...slotProps,
              textField: {
                ...inputProps,
                required,
                error: !!error,
                helperText: error
                  ? typeof customErrorFn === 'function'
                    ? customErrorFn(error)
                    : error.message
                  : inputProps?.helperText || rest.helperText,
                inputProps: {
                  readOnly: !!textReadOnly,
                  ...inputProps?.inputProps,
                },
              },
            }}
            format="DD/MM/YYYY"
          />
        );
      }}
    />
  );
}
