import React, { useEffect, useMemo, useState } from 'react';

import { ArticleOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import DownloadInsights from './InsightComponents/saveInsights';
import { useUserData } from '../../App/Component/UserDataProvider';
import { download_file } from '../../Draft/Component/Helper';
import CheckboxButtonGroup from '../../RiverusUI/Components/CheckboxButtonGroup';
import ControlledTextField from '../../RiverusUI/Components/ControlledTextField';
import CheckBoxFilled from '../../RiverusUI/Components/Icons/CheckboxFilled';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import {
  editContract,
  fetchContractById,
  fetchContractingParty,
  fetchDownloadUrl,
  terminateContract,
} from '../../Services/DocumentLibrary';

interface MenuOption {
  value: string;
  label: string;
  startIcon?: any;
}

interface Props {
  updatedClauseData: any;
  sentenceData: any;
}

const downloadMenu: MenuOption[] = [
  {
    value: 'download_file',
    label: 'Download file',
    startIcon: <ArticleOutlined />,
  },
];

const DocumentHeader: React.FC<Props> = ({
  updatedClauseData,
  sentenceData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openTerminateModal, setOpenTerminateModal] = useState<boolean>(false);
  const [openAmendmentModal, setOpenAmendmentModal] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { user_id, userIsAdmin } = useUserData();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goBackUrl = '/documentlibrary';

  const { data: contract_data } = useQuery({
    queryKey: ['get_contract_data_by_id'],
    queryFn: async () => await fetchContractById(atob(id as string)),
    enabled: !!atob(id as string),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    resetField,
    setValue,
    watch,
  } = useForm();

  const selectedAmendment = watch('select_amendment') || [];

  useEffect(() => {
    if (contract_data && isEdit) {
      setValue('title', contract_data?.title);
    }
  }, [contract_data, isEdit]);

  const { mutate: downloadFileMutate } = useMutation({
    mutationFn: fetchDownloadUrl,
    onSuccess: (response) => {
      download_file(response);
      handleClose();
    },
  });

  const onCancel = () => {
    resetField('title');
    setIsEdit(false);
  };

  const { mutate: updateContract } = useMutation({
    mutationFn: editContract,
    onSuccess: () => {
      enqueueSnackbar('Contract name updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_contract_data_by_id'],
      });
      onCancel();
    },
    onError: (error: any) => {
      const responseData =
        error?.response?.data?.__all__?.[0] ||
        'Failed to update Contract name!';
      enqueueSnackbar(responseData, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: getContractParties } = useQuery({
    queryKey: ['get_contract_party'],
    queryFn: async () => await fetchContractingParty(contract_data?.id),
    enabled: !!contract_data?.id,
  });

  const contracting_parties = useMemo(() => {
    let raw_content = null;
    let edited_content = null;

    if (getContractParties != null) {
      raw_content = getContractParties?.raw_content;
      edited_content = getContractParties?.edited_content;
    }
    if (!raw_content) {
      raw_content = {
        contracting_parties: [],
      };
    }
    if (!edited_content) {
      edited_content = {
        update: [],
      };
    }
    const diff = changesets.unflattenChanges(edited_content.update);
    const changeList = changesets.applyChangeset(raw_content, diff);
    return changeList?.contracting_parties;
  }, [getContractParties]);

  const onSubmit = (data: any) => {
    const payload = {
      id: contract_data?.id,
      body: data,
    };
    if (isDirty) updateContract(payload);
  };

  const handleCloseTerminateModal = () => {
    setOpenTerminateModal(false);
  };

  const { mutate: terminateMutation, isPending: loadingTermination } =
    useMutation({
      mutationKey: ['terminate_contract'],
      mutationFn: terminateContract,
      onSuccess: () => {
        enqueueSnackbar('Contract terminated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        queryClient.invalidateQueries({
          queryKey: ['get_contract_data_by_id'],
        });
        if (openTerminateModal) {
          handleCloseTerminateModal();
        }
        if (openAmendmentModal) {
          setOpenAmendmentModal(false);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to terminate contract!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const handleTerminateContract = () => {
    if (contract_data?.amendment?.length) {
      setOpenAmendmentModal(true);
      handleCloseTerminateModal();
    } else {
      const payload = {
        contract_ids: [contract_data?.id],
        terminate_status: true,
      };
      terminateMutation(payload);
    }
  };

  const handleAmendmentTermination = (value: boolean) => {
    let payload = {
      contract_ids: [contract_data?.id],
      terminate_status: true,
    };
    if (value) {
      payload = {
        contract_ids: [contract_data?.id, ...selectedAmendment],
        terminate_status: true,
      };
    }
    terminateMutation(payload);
  };

  const userCanDoAction = useMemo(() => {
    const userIsContractCreator =
      contract_data?.creator_details?.id === user_id;
    const userIsOwner = contract_data?.owners?.some(
      (ownerItem: any) => ownerItem?.id === user_id
    );
    if (userIsContractCreator || userIsOwner || userIsAdmin) {
      return true;
    }
    return false;
  }, [contract_data, userIsAdmin, user_id]);

  const isExpiredContract = useMemo(() => {
    const endDateObj = new Date(contract_data?.end_date);
    const currentDate = new Date();
    // Compare endDate with current date
    return contract_data?.end_date ? endDateObj < currentDate : false;
  }, [contract_data]);

  return (
    <Box>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          className="header-wrapper"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Link
              href={goBackUrl}
              color="riPrimary.500"
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ArrowBackIcon fontSize="small" sx={{ marginRight: '3px' }} />
              Back
            </Link>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            padding="10px 0"
            justifyContent="center"
          >
            {contract_data?.title && !isEdit && (
              <Button
                variant="text"
                sx={{
                  fontSize: '16px',
                  fontWeight: '600',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setIsEdit(true)}
                endIcon={<EditOutlinedIcon />}
              >
                {contract_data?.title}
              </Button>
            )}
            {isEdit && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ControlledTextField
                    name="title"
                    control={control}
                    label="Contract Name"
                    fullWidth
                  />
                  <IconButton onClick={onCancel}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Stack>
              </form>
            )}

            {contract_data && !contract_data?.terminate_status && (
              <>
                {isExpiredContract ? (
                  <Chip
                    label="Expired"
                    sx={{ background: '#ff4d4f', color: 'white' }}
                  />
                ) : (
                  <Chip
                    label="Active"
                    sx={{
                      background: '#87d068',
                      color: 'white',
                    }}
                  />
                )}
              </>
            )}
            {contract_data?.terminate_status && <Chip label="Terminated" />}
          </Stack>
          <Stack
            direction="row"
            flex={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={handleClick}
              variant="text"
              startIcon={<FileDownloadOutlinedIcon />}
              endIcon={<ArrowDropDownIcon />}
            >
              Download
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box width="200px">
                {downloadMenu?.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => downloadFileMutate(contract_data?.id)}
                  >
                    {option.startIcon}
                    <Typography marginLeft={2}> {option.label}</Typography>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem disabled={!updatedClauseData}>
                  <FileDownloadOutlinedIcon />
                  <DownloadInsights
                    insightsData={updatedClauseData}
                    contracting_parties={contracting_parties}
                    sentenceData={sentenceData}
                    quickIntel={{
                      fileName: contract_data?.file_name,
                      metaData: [],
                    }}
                  />
                </MenuItem>
              </Box>
            </Menu>
            {userCanDoAction &&
              !isExpiredContract &&
              contract_data &&
              !contract_data?.terminate_status && (
                <Button
                  variant="contained"
                  onClick={() => setOpenTerminateModal(true)}
                >
                  Terminate Contract
                </Button>
              )}
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
      {openTerminateModal && (
        <ReusableConfirmationModal
          open={openTerminateModal}
          onClose={handleCloseTerminateModal}
          onConfirm={handleTerminateContract}
          title="Terminate Contract"
          confirmBtnText="Yes, Terminate"
          cancelBtnText=" Cancel Termination"
          buttonLoading={loadingTermination}
        >
          <Stack>
            <Typography>
              are you sure? You will not be able to reverse the decision
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
      {openAmendmentModal && (
        <ReusableConfirmationModal
          open={openAmendmentModal}
          onClose={() => handleAmendmentTermination(false)}
          onConfirm={() => handleAmendmentTermination(true)}
          title="Terminate Amendments"
          confirmBtnText="Yes, Terminate The Amendments"
          cancelBtnText="Terminate only master contract"
        >
          <Stack>
            <Typography>Select amendment</Typography>
            <CheckboxButtonGroup
              name="select_amendment"
              control={control}
              options={contract_data?.amendment}
              labelKey="file_name"
              valueKey="id"
              checkBoxProps={{
                checkedIcon: (
                  <CheckBoxFilled
                    style={{ fill: 'none !important' }}
                    color="#88305F"
                  />
                ),
              }}
              labelProps={{
                sx: {
                  padding: '2px 2px 2px 10px',
                },
              }}
            />
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Box>
  );
};

export default DocumentHeader;
