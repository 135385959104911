import React from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  addCategory,
  fetchAllCategories,
  fetchContractList,
  updateContractType,
} from '../../../Services/dictionary';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const CreateCategoryForm: React.FC<IProps> = ({ open, onClose }) => {
  const methods = useForm();
  const { handleSubmit, control, reset } = methods;

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseModal = () => {
    queryClient.invalidateQueries({
      queryKey: ['admin-contract-category'],
    });
    onClose();
    reset();
  };

  const { data: contractOption, isLoading: contractLoading } = useQuery({
    queryKey: ['contract-type-list'],
    queryFn: fetchContractList,
  });

  const { data: categoryOptions, isLoading: categoryLoading } = useQuery({
    queryKey: ['contract-category-list'],
    queryFn: fetchAllCategories,
    select: (response: any) => response?.results,
  });

  const { mutate: addCategoryMutation } = useMutation({
    mutationKey: ['add-category'],
    mutationFn: addCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contract-category-list'] });
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.name?.[0];
      enqueueSnackbar(
        `${responseData || 'Failed to create contract category'}`,
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    },
  });

  const { mutate: updateContractMutation, isPending: loadingContract } =
    useMutation({
      mutationKey: ['update-contract'],
      mutationFn: updateContractType,
      onSuccess: () => {
        enqueueSnackbar('Contract updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        handleCloseModal();
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.__all__?.[0];
        enqueueSnackbar(`${responseData || 'Failed to update contract'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const onSubmit = (formData: any) => {
    if (!formData?.contract) {
      handleCloseModal();
    } else {
      const payload = {
        id: formData?.contract,
        data: {
          category: formData?.category,
        },
      };
      updateContractMutation(payload);
    }
  };

  return (
    <CustomModal
      maxWidth="md"
      title="Add Contract Category"
      open={open}
      handleClose={handleCloseModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: '400px', padding: '30px 10px' }} gap={2}>
            <RISelectComponent
              name="category"
              options={sortListAlphabetical(categoryOptions)}
              control={control}
              loading={categoryLoading}
              label="Add Or Select Contract Category"
              isMultiselect
              renderCustomComponent={(value: any, props) => (
                <CustomChip {...props} label={value?.name} />
              )}
              canCreateNew
              addNewValue={(value) => addCategoryMutation({ name: value })}
            />
            <RISelectComponent
              name="contract"
              options={sortListAlphabetical(contractOption)}
              control={control}
              loading={contractLoading}
              label="Link to Contract Type"
            />
            <LoadingButton
              variant="contained"
              sx={{ whiteSpace: 'nowrap' }}
              type="submit"
              loading={loadingContract}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateCategoryForm;
