/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { getPara } from '../../DocumentView/Component/ClauseComponent/utils/ClauseTypeUtils';
import { handleSeeMoreAndLess } from '../../Draft/Component/Helper';
import PopoverPopupState from '../../RiverusUI/Components/Popups/PopoverPopupState';

interface Props {
  clauseTypeData: any;
  filteredData: any;
}

const FilterTableView = (props: Props) => {
  const { clauseTypeData, filteredData } = props;
  return (
    <Box sx={{ width: '65vw', maxWidth: '75vw', overflowX: 'scroll' }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'riPrimary.10' }}>
            <TableCell sx={{ fontWeight: 600 }}>Clause Type</TableCell>
            {filteredData?.map((file: any, index: number) => (
              <TableCell
                sx={{
                  fontWeight: 600,
                  minWidth: '300px',
                  textTransform: 'capitalize',
                }}
                key={`file-name-${index}`}
              >
                {file.file_name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {clauseTypeData
            ?.filter((data: any) => data.checked)
            ?.map((clause: any, index: number) => (
              <TableRow key={`clause-${index}`}>
                <TableCell sx={{ fontWeight: 600 }}>
                  {clause.display_name}
                </TableCell>
                {filteredData?.map((file: any, index: number) => {
                  const para = clause?.[file.id];
                  return (
                    <TableCell key={`para-${index}`}>
                      {para?.length > 0 ? (
                        <Stack
                          key={`file-${index}`}
                          height="250px"
                          sx={{ overflowY: 'auto' }}
                          spacing={2}
                        >
                          <Typography
                            variant="body1"
                            sx={{ overflowWrap: 'break-word' }}
                            dangerouslySetInnerHTML={{
                              __html: handleSeeMoreAndLess(
                                (
                                  clause?.[file.id]?.[0][clause.key] ||
                                  getPara(
                                    file?.extracted.raw_content,
                                    clause?.[file.id]?.[0].para_id,
                                    clause?.[file.id]?.[0].sentence_id,
                                    clause?.[file.id]?.[0].table &&
                                      clause?.[file.id]?.[0]?.table[0]?.row,
                                    clause?.[file.id]?.[0].table &&
                                      clause?.[file.id]?.[0]?.table[0]?.column
                                  )
                                )?.toString(),
                                40
                              ),
                            }}
                          />
                          {((
                            para?.[0][clause.key] ||
                            getPara(
                              file?.extracted.raw_content,
                              clause?.[file.id]?.[0].para_id,
                              clause?.[file.id]?.[0].sentence_id,
                              clause?.[file.id]?.[0].table &&
                                clause?.[file.id]?.[0]?.table[0]?.row,
                              clause?.[file.id]?.[0].table &&
                                clause?.[file.id]?.[0]?.table[0]?.column
                            )
                          )
                            .toString()
                            ?.split(/\s+/)?.length > 20 ||
                            para?.length > 1) && (
                            <PopoverPopupState
                              id={`${clause.clause_name}-${file.id}`}
                              renderIcon={() => (
                                <Typography
                                  key={`${clause.clause_name}-${file.id}`}
                                  sx={{
                                    color: 'riNeutralVariant.700',
                                    fontSize: '14px',
                                  }}
                                >
                                  See More
                                </Typography>
                              )}
                            >
                              <Stack maxWidth="600px" gap={2}>
                                {para?.map((para: any, paraIndex: number) => (
                                  <Typography
                                    key={`popup_para_${paraIndex}`}
                                    variant="body1"
                                    sx={{ overflowWrap: 'break-word' }}
                                    dangerouslySetInnerHTML={{
                                      __html: `${paraIndex + 1}).${
                                        para?.[clause.key] ||
                                        getPara(
                                          file?.extracted.raw_content,
                                          clause?.[file.id]?.[0].para_id,
                                          clause?.[file.id]?.[0].sentence_id,
                                          clause?.[file.id]?.[0].table &&
                                            clause?.[file.id]?.[0]?.table[0]
                                              ?.row,
                                          clause?.[file.id]?.[0].table &&
                                            clause?.[file.id]?.[0]?.table[0]
                                              ?.column
                                        )
                                      }`,
                                    }}
                                  />
                                ))}
                              </Stack>
                            </PopoverPopupState>
                          )}
                        </Stack>
                      ) : (
                        <p>no data found</p>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default FilterTableView;
