import React, { useState } from 'react';

import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Control, Controller } from 'react-hook-form';

import { postMarkAllRead } from '../../../Services/Notification';

interface Props {
  notifications_ids: any[];
  control: Control;
  unreadNotification: boolean;
}

const NotificationDropDownBtn: React.FC<Props> = ({
  notifications_ids,
  control,
  unreadNotification,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const { mutate: markAllAsRead } = useMutation({
    mutationKey: ['all_notifications_mark_as_read'],
    mutationFn: postMarkAllRead,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get_all_notifications'],
      });
    },
  });

  const handleMarkAllAsRead = () => {
    const payload = {
      mark_all_read: true,
      notifications_id: notifications_ids?.map(
        (notification: any) => notification?.id || notification?.notification_id
      ),
    };
    markAllAsRead(payload);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon sx={{ color: '#88305F' }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleMarkAllAsRead}>
          <DoneAllRoundedIcon />
          <Typography marginLeft={2}>Mark all as read</Typography>
        </MenuItem>
        <MenuItem sx={{ width: '300px' }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Stack direction="row">
              <MailOutlineRoundedIcon />
              <Typography marginLeft={2}>Show only unread</Typography>
            </Stack>
            <Controller
              name="unread"
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={unreadNotification || false} />
              )}
            />
          </Stack>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default NotificationDropDownBtn;
