import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import SendReminderNowModal from './SendReminderNowModal';
import { useUserData } from '../../../App/Component/UserDataProvider';
import { fetchExternalChecklistData } from '../../../ExternalUserFlow/Services/Draft';
import ConfirmationModal from '../../../RiverusUI/Components/ConfirmationModalComponent';
import DatePickerElement from '../../../RiverusUI/Components/ControlledDatePicker';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import DeleteIcon from '../../../RiverusUI/Components/Icons/DeleteIcon';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import ReusableConfirmationModal from '../../../RiverusUI/Components/ReusableConfirmationModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  deleteCompleteDraftField,
  externalUsers,
  fetchChecklistData,
  fetchExternalUsersList,
} from '../../../Services/Draft';
import { assignOptions } from '../DraftChecklist/StaticData';
import { formatPayloadDate, sortListAlphabetical } from '../Helper';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  title: string;
  heading: string;
  approversData: any;
  handleAssignee: (
    payload: any,
    selectUser: any,
    selectExternalUser: any
  ) => void;
  handleReminder: (payload: any) => void;
  handleUpdateReminder: (payload: any) => void;
  assigneeDialogPropData: any;
  completeDraftReminder: any;
  draftData: any;
  isExternal?: boolean;
  optionalFields: any;
  updateDraftData?: (payload: any) => void;
}

const AssigneeModal: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    title,
    heading,
    approversData,
    handleAssignee,
    assigneeDialogPropData,
    handleReminder,
    completeDraftReminder,
    handleUpdateReminder,
    draftData,
    isExternal,
    optionalFields,
    updateDraftData,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    resetField,
    formState: { isValid },
  } = methods;
  const assignee_type = watch('assignee_type') || '';
  const selectUser = watch('select_user') || '';
  const email = watch('email') || '';
  const first_name = watch('first_name') || '';
  const last_name = watch('last_name') || '';
  const selectExternalUser = watch('select_external_user') || '';

  const { id } = useParams<{
    id: string;
  }>();

  const queryClient = useQueryClient();

  // login user data
  const { user_id, userIsCreator } = useUserData();

  //login user is owner
  const userIsOwner = useMemo(
    () => draftData?.owners?.find((owner: any) => owner?.id === user_id),
    [draftData, user_id]
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [openSendReminderNow, setOpenSendReminderNow] =
    useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showExternalFields, setShowExternalFields] = useState(false);
  const [getUserDetailState, setGetUserDetailState] = useState<any>(null);
  const [getExternalUserDetailState, setGetExternalUserDetailState] =
    useState<any>(null);

  const { data: getDraftCheckList } = useQuery({
    queryKey: ['draft_checkList'],
    queryFn: async () =>
      isExternal
        ? await fetchExternalChecklistData(draftData?.draftID)
        : await fetchChecklistData(draftData?.draftID),
    select: (response: any) => {
      const filterNotDeletedItem = response?.results?.filter(
        (item: any) => !item?.deleted_status
      );
      return filterNotDeletedItem;
    },
    enabled: !!draftData?.draftID,
  });

  const checklistCollaborators = useMemo(() => {
    return (getDraftCheckList ?? [])
      ?.filter((item: any) => item?.user)
      ?.map((item: any) => item?.user);
  }, [getDraftCheckList]);

  const handleRemoveAssignment = () => {
    reset({
      assignee_type: '',
    });
    setIsEdit(false);
  };

  const { mutate: deleteField } = useMutation({
    mutationKey: ['delete_complete_draft_field'],
    mutationFn: deleteCompleteDraftField,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get_complete_reminder_data'],
      });
      enqueueSnackbar('Assignment removed successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['optional_fields', draftData?.id],
      });
      handleRemoveAssignment();

      const fieldIdToRemoveFrom = assigneeDialogPropData?.id;
      const userIdToRemove = assigneeDialogPropData?.user;
      const updatedFields: any[] = [];
      optionalFields?.forEach((section: any) => {
        const filteredFieldData = section?.field_data?.filter(
          (item: any) =>
            !(item?.id === fieldIdToRemoveFrom && item?.user === userIdToRemove)
        );
        if (filteredFieldData?.length) {
          updatedFields.push({
            ...section,
            field_data: filteredFieldData,
          });
        }
      });

      const remainingFields = updatedFields?.flatMap(
        (section: any) => section?.field_data || []
      );
      const remainingUserIds = new Set(
        remainingFields
          ?.map((field: any) => field?.user)
          .filter((user: any) => user != null)
      );
      const filteredCollaboratorIds =
        draftData?.collaborators
          ?.filter((collaborator: any) =>
            remainingUserIds.has(collaborator?.id)
          )
          ?.map((collaborator: any) => collaborator?.id) || [];
      const finalCollaborators = [
        ...filteredCollaboratorIds,
        ...checklistCollaborators,
      ];
      const updateDraftDataPayload = {
        id: draftData?.id,
        body: {
          collaborators: finalCollaborators,
        },
      };
      if (updateDraftData) {
        updateDraftData(updateDraftDataPayload);
      }
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { data: externalUsersData } = useQuery({
    queryKey: ['external_users_list'],
    queryFn: fetchExternalUsersList,
    select: (response: any) => {
      const groups = response.results.map((data: any) => {
        const name =
          data?.first_name === data?.last_name
            ? data?.first_name
            : `${data.first_name} ${data?.last_name}`;
        return {
          ...data,
          name: `${name} - ${data.email}`,
        };
      });
      return groups;
    },
  });

  const { mutate: externalUsersMutation } = useMutation({
    mutationKey: ['external_users'],
    mutationFn: externalUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['external_users_list'],
      });
      enqueueSnackbar('External fields saved successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });

      setShowExternalFields(false);
      resetField('first_name');
      resetField('last_name');
      resetField('email');
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.non_field_errors?.[0];
      enqueueSnackbar(`${responseData || 'Failed to save external user'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const getExternalUserDetail = useMemo(
    () =>
      externalUsersData?.find(
        (item: any) =>
          item?.email === selectExternalUser || item?.id === selectExternalUser
      ),
    [externalUsersData, selectExternalUser]
  );

  useEffect(() => {
    if (getExternalUserDetail) {
      setGetExternalUserDetailState(getExternalUserDetail);
    }
  }, [getExternalUserDetail]);

  useEffect(() => {
    if (assigneeDialogPropData) {
      setValue('assignee_type', assigneeDialogPropData?.user_type);
      if (assigneeDialogPropData?.user_type === 'internal') {
        setValue('select_user', assigneeDialogPropData?.user);
      }
      if (assigneeDialogPropData?.user_type === 'external') {
        setValue(
          'select_external_user',
          assigneeDialogPropData?.user || selectExternalUser
        );
      }
    }
    if (assigneeDialogPropData?.reminder?.id) {
      setValue('due_date', dayjs(assigneeDialogPropData?.reminder?.due_date));
    }
    if (assigneeDialogPropData?.user_type) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [assigneeDialogPropData, completeDraftReminder]);

  const getUserDetail = useMemo(
    () =>
      approversData?.find(
        (item: any) => item?.email === selectUser || item?.id === selectUser
      ),
    [approversData, selectUser]
  );

  useEffect(() => {
    if (getUserDetail) {
      setGetUserDetailState(getUserDetail);
    }
  }, [getUserDetail]);

  const handleAddNewClick = () => {
    setShowExternalFields((prev) => !prev);
  };

  const handleNewFieldsSave = () => {
    const payload = {
      first_name: first_name,
      last_name: last_name,
      username: first_name?.trim(),
      email: email,
      assignee_type: 'external',
    };
    externalUsersMutation(payload);
  };

  const onSubmit = useCallback(
    (data: any) => {
      const payload = {
        user_name:
          data?.assignee_type === 'external'
            ? getExternalUserDetailState?.first_name ===
              getExternalUserDetailState?.last_name
              ? getExternalUserDetailState?.first_name
              : `${getExternalUserDetailState?.first_name} ${getExternalUserDetailState?.last_name}`
            : getUserDetailState?.name || data?.name,
        id: data?.select_external_user || data?.select_user,
        user: data?.select_external_user || data?.select_user,
        user_id: data?.select_external_user || data?.select_user,
        email:
          data?.assignee_type === 'external'
            ? getExternalUserDetailState?.email
            : getUserDetailState?.email || data?.email,
        assignee_type: data?.assignee_type,
        ...((selectUser && selectUser === assigneeDialogPropData?.user) ||
        (selectExternalUser &&
          selectExternalUser === assigneeDialogPropData?.user)
          ? {}
          : { assigned_on: dayjs().format('YYYY-MM-DDTHH:mm:ssZ') }),
      };
      handleAssignee(payload, selectUser, selectExternalUser);

      if (data?.due_date) {
        let reminderPayload: any = {
          due_date: formatPayloadDate(new Date(data?.due_date)),
        };

        if (data?.assignee_type === 'external') {
          reminderPayload = {
            ...reminderPayload,
            external_user: [
              {
                name: getExternalUserDetailState?.first_name,
                email: getExternalUserDetailState?.email,
                id: getExternalUserDetailState?.id,
              },
            ],
          };
        }
        if (!completeDraftReminder?.id) {
          reminderPayload = {
            ...reminderPayload,
            reminder_type: 'items',
            app: 'Stylus',
            draft: id,
            name: `${draftData?.contractName} - Template Field Assignment- Reminder`,
            draft_item: assigneeDialogPropData?.id,
            field_name: assigneeDialogPropData?.field_name,
          };

          if (data?.assignee_type === 'internal') {
            reminderPayload = {
              ...reminderPayload,
              users: getUserDetail?.id,
            };
          }
          handleReminder(reminderPayload);
        }

        if (completeDraftReminder?.id) {
          reminderPayload = {
            ...reminderPayload,
            id: completeDraftReminder?.id,
          };
          if (data?.assignee_type === 'internal') {
            reminderPayload = {
              ...reminderPayload,
              users: [getUserDetail?.id],
            };
          }
          handleUpdateReminder(reminderPayload);
        }
      }
    },
    [
      getUserDetail,
      getUserDetailState,
      getExternalUserDetailState,
      handleAssignee,
      assigneeDialogPropData,
      completeDraftReminder,
      handleReminder,
      handleUpdateReminder,
      draftData,
      getExternalUserDetail,
    ]
  );

  useEffect(() => {
    if (assignee_type === 'external') {
      resetField('select_user');
    }
    if (assignee_type === 'internal') {
      resetField('select_external_user');
    }
  }, [assignee_type]);

  const handleCloseDialog = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleConfirm = useCallback(() => {
    setOpenConfirmationModal(false);
    handleCloseDialog();
  }, [handleCloseDialog]);

  return (
    <React.Fragment>
      <CustomModal
        title={title}
        open={open}
        handleClose={handleCloseDialog}
        maxWidth="md"
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack width="600px" spacing={1} mt={2}>
              <Typography variant="body1" fontWeight={700}>
                {heading} :
              </Typography>

              <RadioButtonGroup
                name="assignee_type"
                control={control}
                row
                required
                options={assignOptions}
                valueKey="value"
                disabled={isEdit}
              />

              {assignee_type === 'internal' && (
                <RISelectComponent
                  label="Select User"
                  name="select_user"
                  control={control}
                  options={sortListAlphabetical(approversData)}
                  required
                  valueKey="id"
                  readOnly={isEdit}
                />
              )}

              {assignee_type === 'external' && (
                <Stack spacing={2}>
                  <Button
                    sx={{
                      width: 'fit-content',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      gap: '6px',
                    }}
                    onClick={handleAddNewClick}
                    disabled={isEdit}
                  >
                    <AddCircleOutlineRoundedIcon fontSize="small" /> Add New
                  </Button>
                  {showExternalFields && (
                    <Stack spacing={2}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <ControlledTextField
                          name="first_name"
                          control={control}
                          label="First Name"
                          fullWidth
                          required
                        />
                        <ControlledTextField
                          name="last_name"
                          control={control}
                          label="Last Name"
                          fullWidth
                        />
                      </Stack>
                      <ControlledTextField
                        name="email"
                        control={control}
                        label="Email"
                        fullWidth
                        type="email"
                        required
                      />
                      <Button
                        variant="outlined"
                        sx={{ width: 'fit-content', alignSelf: 'end' }}
                        onClick={handleNewFieldsSave}
                      >
                        Save
                      </Button>
                    </Stack>
                  )}

                  <RISelectComponent
                    label="Select External User"
                    name="select_external_user"
                    control={control}
                    options={sortListAlphabetical(externalUsersData)}
                    required
                    valueKey="id"
                    readOnly={isEdit}
                  />
                </Stack>
              )}

              {assignee_type && (
                <Stack width="100%" spacing={1}>
                  <Typography fontSize="14px" fontWeight="500" pb="10px">
                    Set a due date for the assignment:
                  </Typography>
                  <DatePickerElement
                    name="due_date"
                    label="Due Date"
                    disablePast
                    disabled={isEdit}
                  />
                </Stack>
              )}

              {(userIsOwner || userIsCreator) && (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  mt={1}
                >
                  <Stack direction="row">
                    {isEdit ? (
                      <Button
                        variant="contained"
                        onClick={() => setIsEdit(false)}
                      >
                        Edit
                      </Button>
                    ) : (
                      <LoadingButton
                        type="submit"
                        loadingPosition="start"
                        variant="contained"
                      >
                        Save
                      </LoadingButton>
                    )}
                    <Button
                      variant="outlined"
                      onClick={() => setOpenConfirmationModal(true)}
                    >
                      Cancel
                    </Button>
                  </Stack>
                  {isValid && isEdit && userIsOwner && (
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<NotificationsIcon />}
                      onClick={() => setOpenSendReminderNow(true)}
                    >
                      Send a reminder now
                    </Button>
                  )}
                </Stack>
              )}

              {assigneeDialogPropData?.field_name &&
                (userIsOwner || userIsCreator) && (
                  <Typography variant="caption">
                    To save this assignment please click on &quot;Save
                    Draft&quot; on top of the page
                  </Typography>
                )}

              {isValid &&
                (userIsOwner || userIsCreator) &&
                assigneeDialogPropData?.user_type && (
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => setOpenDeleteDialog(true)}
                    sx={{
                      padding: '5px',
                      width: 'fit-content',
                    }}
                  >
                    Remove assignment
                  </Button>
                )}
            </Stack>
          </form>
        </FormProvider>
      </CustomModal>

      {openConfirmationModal && (
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onConfirm={handleConfirm}
          title="Confirmation"
          message="Are you sure you want to close this?"
        />
      )}
      {openSendReminderNow && (
        <SendReminderNowModal
          open={openSendReminderNow}
          onClose={() => setOpenSendReminderNow(false)}
          handleClose={onClose}
          reminderData={completeDraftReminder}
        />
      )}
      {openDeleteDialog && (
        <ReusableConfirmationModal
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          title="Delete Assignment"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteField(assigneeDialogPropData?.id)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected assignee will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default AssigneeModal;
