import React, { ReactElement } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import dayjs from 'dayjs';

export const reminder = [
  { name: 'Don’t remind me', id: 'don’t_remind_me' },
  { name: '1 day before', id: '1_day_before' },
  { name: '3 days before', id: '3_days_before' },
  { name: '5 days before', id: '5_days_before' },
  { name: '7 days before', id: '7_days_before' },
  { name: '10 days before', id: '10_days_before' },
  { name: '15 days before', id: '15_days_before' },
  { name: '30 days before', id: '30_days_before' },
];

export const reminderStatus = [
  { name: 'Not started', id: 'Not Started' },
  { name: 'In progress', id: 'In Progress' },
  { name: 'Completed', id: 'Completed' },
  { name: 'Past due date', id: 'Past Due Date' },
  { name: 'Flagged', id: 'Flagged' },
];

export const calculateDayDifference = (dueDate?: string | null): number => {
  if (!dueDate) return 0;
  const dDueDate = dayjs(dueDate);
  const currentDate = dayjs();
  return dDueDate.diff(currentDate, 'day');
};

export const isLastDateOfMonth = (date: string) => {
  const currentDate = dayjs(date);
  const lastDayOfMonth = currentDate.endOf('month');
  return currentDate.isSame(lastDayOfMonth, 'day');
};

export const sortByCreatedDate = (reminders: any[]) => {
  return reminders?.sort((a, b) => {
    const dateA = new Date(a.created.split('/').reverse().join('-'));
    const dateB = new Date(b.created.split('/').reverse().join('-'));
    return dateB.getTime() - dateA.getTime();
  });
};

export const status_icon: Record<string, ReactElement> = {
  'Not Started': <TimerOutlinedIcon />,
  'In Progress': <AccessTimeIcon />,
  Completed: <CheckCircleOutlineIcon />,
  'Past Due Date': <ErrorOutlineIcon />,
};

export const iconColor: Record<string, string> = {
  'Not Started': '#49454F',
  'In Progress': '#866133',
  Completed: '#3F7713',
  'Past Due Date': '#7B0D0D',
};

export const RecurringOptions = [
  {
    value: 'last_day_of_month',
    title: 'Last day of a month',
  },
  {
    value: 'day_of_month',
    title: 'Fixed day of a month',
  },
  {
    value: 'recreate_every',
    title: 'Recreate every',
  },
];
