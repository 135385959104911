import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import TabIcon from '@mui/icons-material/Tab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import SupportDocsDrawer from './SupportDocsDrawer';
import { useUserData } from '../../../App/Component/UserDataProvider';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import {
  addDraftChecklistComments,
  deleteChecklistComment,
} from '../../../Services/Draft';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';
import { droppedMsg } from '../Helper';

interface Props {
  setOpenUploadDialog: Dispatch<SetStateAction<any>>;
  handleOpenDeleteDialog: (id: string, data: any) => void;
  handleOpenAssigneeDialog: (data: any) => void;
  handleOpenUploadDialog: (id: string) => void;
  handleActiveStatus: (checkedValue: boolean, data: any) => void;
  checklistData: any;
  isExternal?: boolean;
  userIsOwner: boolean;
  updateReminder?: any;
  isOwner: boolean;
  handleCommentsClick: (clickedApprover: any) => void;
  commentsContainerRef: any;
  accordionExpanded: string;
  setAccordionExpanded: Dispatch<SetStateAction<string>>;
  showCommentsInput: string;
  setShowCommentsInput: Dispatch<SetStateAction<string>>;
  openAssignDialog: boolean;
  isButtonClicked: boolean;
  setIsButtonClicked: Dispatch<SetStateAction<boolean>>;
  activeChecklistId: any;
  setActiveChecklistId: any;
  isDraftDropped: boolean;
}

const Checklist: React.FC<Props> = ({
  handleOpenDeleteDialog,
  handleOpenAssigneeDialog,
  handleOpenUploadDialog,
  handleActiveStatus,
  checklistData,
  isExternal,
  userIsOwner,
  updateReminder,
  isOwner,
  handleCommentsClick,
  commentsContainerRef,
  accordionExpanded,
  setAccordionExpanded,
  showCommentsInput,
  setShowCommentsInput,
  openAssignDialog,
  isButtonClicked,
  setIsButtonClicked,
  activeChecklistId,
  setActiveChecklistId,
  isDraftDropped,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, reset } = useForm();
  const queryClient = useQueryClient();
  const { user_data, user_id } = useUserData();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [supportDocList, setSupportDocList] = useState<any[]>([]);
  const [isCommentHovered, setIsCommentHovered] = useState<{
    [key: string]: boolean;
  }>({});

  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSupportDocList([]);
  };

  const handleCheckboxStatus = (
    reminder_id: string,
    checked: boolean,
    support_doc: any[]
  ) => {
    if (reminder_id) {
      const status = checked
        ? 'Completed'
        : support_doc && support_doc?.length > 0
          ? 'In Progress'
          : 'Not Started';

      const updateChecklistPayload = {
        id: reminder_id,
        body: {
          status: status,
        },
      };
      updateReminder(updateChecklistPayload);
    }
  };

  const toggleInput = useCallback(
    (checklistId: string) => {
      if (isButtonClicked) {
        setIsButtonClicked(false);
      }
      setActiveChecklistId((prevState: any) => {
        if (showCommentsInput) {
          return {
            ...prevState,
            [checklistId]: false,
          };
        }
        return prevState;
      });
      setActiveChecklistId((prevState: any) => {
        if (showCommentsInput !== checklistId) {
          return {
            ...prevState,
            [checklistId]: !prevState[checklistId] || false,
          };
        }
        return prevState;
      });
      setShowCommentsInput('');
    },
    [setActiveChecklistId, showCommentsInput, isButtonClicked]
  );

  const { mutate: addChecklistCommentMutation } = useMutation({
    mutationFn: addDraftChecklistComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['draft_checkLists'],
      });
      reset();
      setIsButtonClicked(false);
    },
  });

  const { mutate: delete_checklist_comment } = useMutation({
    mutationKey: ['delete-checklist-comment'],
    mutationFn: deleteChecklistComment,
    onSuccess: () => {
      setIsButtonClicked(false);
      enqueueSnackbar('Comment deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_checkLists'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Comment!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleCommentHover = (commentId: string, isHovered: boolean) => {
    setIsCommentHovered((prevState) => ({
      ...prevState,
      [commentId]: isHovered,
    }));
  };

  const onSubmit = useCallback(
    (data: any, assigneeChecklist: any) => {
      const checklistItem = checklistData?.find(
        (checklist: any) => checklist?.user === assigneeChecklist?.user
      );
      const commentedForId = checklistItem ? (checklistItem as any)?.user : '';
      const payload = {
        checklist: assigneeChecklist?.id,
        comment: data?.[`comment-${assigneeChecklist?.user}`] || '',
        commented_for: commentedForId,
      };
      addChecklistCommentMutation(payload);
    },
    [checklistData, addChecklistCommentMutation, toggleInput]
  );

  const currentChecklistId = useMemo(() => {
    return checklistData?.find(
      (value: any) =>
        value?.id === showCommentsInput || value?.id === accordionExpanded
    )?.id;
  }, [checklistData, showCommentsInput, accordionExpanded]);

  // On re-routing scroll to the corresponding checklist
  useEffect(() => {
    const element = refs.current[currentChecklistId];
    if (currentChecklistId && element) {
      setTimeout(() => {
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 300);
    }
  }, [isButtonClicked, currentChecklistId]);

  return (
    <React.Fragment>
      <List sx={{ width: '100%', bgcolor: 'transparent' }}>
        {checklistData?.map((value: any) => {
          const fullName: string[] = value?.user_name?.split(' ');
          return (
            !value.deleted_status && (
              <Stack
                ref={(el) => {
                  refs.current[value.id] = el;
                }}
                sx={{
                  background:
                    value?.comments?.length > 0 ||
                    showCommentsInput === value?.id ||
                    activeChecklistId[value?.id]
                      ? '#88305F1F'
                      : 'transparent',
                  borderRadius:
                    value?.comments?.length > 0 ||
                    showCommentsInput === value?.id ||
                    activeChecklistId[value?.id]
                      ? '8px'
                      : '0px',
                  marginBottom: '0.8rem',
                }}
              >
                <ListItem
                  key={value}
                  secondaryAction={
                    <Stack direction="row" alignItems="center">
                      {value?.support_doc?.length > 0 && (
                        <Stack direction="row" alignItems="center">
                          <Typography variant="caption">
                            {value?.support_doc?.length} attachment
                          </Typography>
                          <Tooltip
                            title={droppedMsg}
                            arrow
                            disableHoverListener={!isDraftDropped}
                          >
                            <span>
                              <IconButton
                                edge="end"
                                onClick={() => handleOpenUploadDialog(value)}
                                disabled={
                                  value?.active_status || isDraftDropped
                                }
                              >
                                <AttachFileIcon
                                  style={{
                                    rotate: '90deg',
                                  }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Show connected documents">
                            <IconButton
                              edge="end"
                              sx={{ ml: 1 }}
                              onClick={() => {
                                setOpenDrawer(true);
                                setSupportDocList(value?.support_doc);
                              }}
                            >
                              <TabIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      )}
                      {value?.support_doc?.length === 0 && (
                        <Tooltip
                          title={droppedMsg}
                          arrow
                          disableHoverListener={!isDraftDropped}
                        >
                          <span>
                            <IconButton
                              edge="end"
                              onClick={() => handleOpenUploadDialog(value)}
                              disabled={value?.active_status || isDraftDropped}
                            >
                              <FileUploadOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                      {!isExternal && (
                        <>
                          <Tooltip
                            title={droppedMsg}
                            arrow
                            disableHoverListener={!isDraftDropped}
                          >
                            <span>
                              <IconButton
                                edge="end"
                                disabled={userIsOwner || isDraftDropped}
                                sx={{ ml: 1 }}
                                onClick={() => {
                                  handleOpenDeleteDialog(value?.id, value);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </span>
                          </Tooltip>
                          {!value?.user_name && (
                            <Tooltip
                              title={droppedMsg}
                              arrow
                              disableHoverListener={!isDraftDropped}
                            >
                              <span>
                                <IconButton
                                  edge="end"
                                  sx={{ ml: 1 }}
                                  disabled={
                                    userIsOwner ||
                                    value?.active_status ||
                                    isDraftDropped
                                  }
                                  onClick={() =>
                                    handleOpenAssigneeDialog(value)
                                  }
                                >
                                  <PersonAddAltOutlinedIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </>
                      )}

                      {value?.comments?.length === 0 &&
                        value?.user_name &&
                        (isOwner || value?.user === user_id) && (
                          <Tooltip title="Add/Reply Comment">
                            <IconButton
                              edge="end"
                              sx={{
                                ml: 1,
                                fontSize: '14px',
                                marginTop: '0.4rem',
                              }}
                              onClick={() => {
                                toggleInput(value?.id);
                              }}
                              disabled={isDraftDropped}
                            >
                              <ChatBubbleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                      {value?.user_name && (
                        <Stack direction="row" alignItems="center">
                          <Tooltip
                            title={droppedMsg}
                            arrow
                            disableHoverListener={!isDraftDropped}
                          >
                            <span>
                              <IconButton
                                edge="end"
                                sx={{ ml: 1 }}
                                disabled={
                                  userIsOwner ||
                                  value?.active_status ||
                                  isDraftDropped
                                }
                                onClick={() =>
                                  isExternal
                                    ? {}
                                    : handleOpenAssigneeDialog(value)
                                }
                              >
                                <NameAvatar
                                  firstName={fullName?.[0]}
                                  lastName={fullName?.[1]}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                          {!value?.reminder?.due_date && (
                            <Tooltip
                              title={
                                isDraftDropped ? droppedMsg : 'Create Reminder'
                              }
                            >
                              <IconButton
                                disabled={
                                  userIsOwner ||
                                  value?.active_status ||
                                  isDraftDropped
                                }
                              >
                                <EventIcon
                                  onClick={() =>
                                    isExternal
                                      ? {}
                                      : handleOpenAssigneeDialog(value)
                                  }
                                  sx={{
                                    fill: '#88305F',
                                    fontSize: '20px',
                                    marginLeft: '10px',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  }
                  disablePadding
                >
                  <ListItemButton
                    sx={
                      value?.comments?.length > 0 ||
                      activeChecklistId[value?.id]
                        ? {
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }
                        : {}
                    }
                  >
                    <ListItemIcon sx={{ minWidth: 'unset' }}>
                      <Checkbox
                        disabled={userIsOwner || isDraftDropped}
                        edge="start"
                        checked={value?.active_status}
                        onChange={(event: any) => {
                          handleActiveStatus(event.target.checked, value);
                          if (value?.reminder?.id) {
                            handleCheckboxStatus(
                              value?.reminder?.id,
                              event.target.checked,
                              value?.support_doc
                            );
                          }
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Tooltip title={value?.checklist_name}>
                          <Box
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '110px',
                            }}
                          >
                            {value?.checklist_name}
                          </Box>
                        </Tooltip>
                      }
                    />
                  </ListItemButton>
                </ListItem>

                {(value?.comments?.length > 0 ||
                  activeChecklistId[value?.id]) && (
                  <Stack spacing={2} sx={{ padding: '8px 12px 16px' }}>
                    {value?.comments?.length > 0 && (
                      <Accordion
                        sx={{
                          background: 'unset',
                          boxShadow: 'none',
                        }}
                        expanded={accordionExpanded === value?.id}
                      >
                        <AccordionSummary
                          sx={{
                            padding: '0 12px',
                            backgroundColor: '#fbe3e9',
                            borderRadius:
                              accordionExpanded === value?.id
                                ? '10px 10px 0 0'
                                : '10px ',
                          }}
                          onClick={() => {
                            handleCommentsClick(value);
                            setAccordionExpanded((prev: any) =>
                              prev === value?.id ? null : value?.id
                            );
                          }}
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: '#6D264C' }} />
                          }
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1.5}
                          >
                            <Typography
                              variant="body2"
                              fontWeight={600}
                              textTransform="uppercase"
                              color="#6D264C"
                            >
                              Comments
                            </Typography>

                            {value?.unread_comment_count > 0 &&
                              value?.comments?.some(
                                (comment: any) =>
                                  !comment?.is_read &&
                                  comment?.created_by?.id !== user_id &&
                                  value?.user !== value?.created_by
                              ) && (
                                <Typography
                                  variant="caption"
                                  fontWeight={600}
                                  color="#A0597F"
                                  border="2px solid"
                                  borderRadius="10px"
                                  padding="2px 6px"
                                  sx={{ borderStyle: 'dashed' }}
                                >
                                  {value?.unread_comment_count > 1
                                    ? `${value?.unread_comment_count} New Messages`
                                    : `${value?.unread_comment_count} New Message`}
                                </Typography>
                              )}
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: '0 12px 16px',
                            backgroundColor: '#fbe3e9',
                            borderRadius: '0 0 10px 10px',
                          }}
                        >
                          <Stack spacing={2}>
                            <Scrollable
                              maxHeight={250}
                              ref={commentsContainerRef}
                            >
                              <Stack spacing={1} sx={{ paddingRight: '8px' }}>
                                {value?.comments
                                  ?.slice()
                                  .reverse()
                                  .map((comment: any) => (
                                    <Stack
                                      key={comment}
                                      spacing={1}
                                      direction="row"
                                      alignItems="center"
                                      onMouseEnter={() =>
                                        handleCommentHover(comment?.id, true)
                                      }
                                      onMouseLeave={() =>
                                        handleCommentHover(comment?.id, false)
                                      }
                                    >
                                      {comment?.created_by?.id !== user_id && (
                                        <NameAvatar
                                          firstName={
                                            comment?.created_by?.first_name
                                          }
                                          lastName={
                                            comment?.created_by?.last_name
                                          }
                                          avatarStyle={{
                                            width: '32px',
                                            height: '32px',
                                          }}
                                        />
                                      )}
                                      <TextField
                                        key={comment?.id}
                                        value={comment?.comment}
                                        disabled
                                        fullWidth
                                      />
                                      {comment?.created_by?.id === user_id && (
                                        <NameAvatar
                                          firstName={
                                            comment?.created_by?.first_name
                                          }
                                          lastName={
                                            comment?.created_by?.last_name
                                          }
                                          avatarStyle={{
                                            width: '32px',
                                            height: '32px',
                                          }}
                                        />
                                      )}
                                      {isCommentHovered[comment?.id] &&
                                        (comment?.created_by?.id === user_id ||
                                          (!value?.user && isOwner)) &&
                                        !isDraftDropped && (
                                          <Tooltip title="Delete Comment">
                                            <DeleteOutlineIcon
                                              sx={{
                                                cursor: 'pointer',
                                                fontSize: '20px',
                                                color: '#6D264C',
                                              }}
                                              onClick={() =>
                                                delete_checklist_comment(
                                                  comment?.id
                                                )
                                              }
                                            />
                                          </Tooltip>
                                        )}
                                    </Stack>
                                  ))}
                              </Stack>
                            </Scrollable>

                            {value?.comments?.length > 0 &&
                              value?.user &&
                              (value?.user === user_id || isOwner) && (
                                <Stack spacing={1}>
                                  <Stack
                                    component="form"
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    onSubmit={handleSubmit((data) =>
                                      onSubmit(data, value)
                                    )}
                                  >
                                    <ControlledTextField
                                      disabled={isDraftDropped}
                                      name={`comment-${value?.user}`}
                                      control={control}
                                      fullWidth
                                      className={
                                        accordionExpanded === value?.id &&
                                        !openAssignDialog &&
                                        isButtonClicked
                                          ? 'blink-border-animation'
                                          : ''
                                      }
                                      sx={{
                                        background: '#FFF7FA',
                                        borderRadius: '6px',
                                        border:
                                          accordionExpanded === value?.id &&
                                          !openAssignDialog &&
                                          isButtonClicked
                                            ? '2px solid transparent'
                                            : '',
                                      }}
                                    />
                                    <NameAvatar
                                      firstName={user_data?.first_name}
                                      lastName={user_data?.last_name}
                                      avatarStyle={{
                                        width: '32px',
                                        height: '32px',
                                      }}
                                    />
                                  </Stack>
                                  <Link
                                    fontSize="14px"
                                    display="flex"
                                    alignItems="center"
                                    whiteSpace="nowrap"
                                    sx={{
                                      textDecoration: 'unset',
                                    }}
                                  >
                                    <ChatBubbleOutlineIcon
                                      sx={{
                                        mr: '6px',
                                        fontSize: '14px',
                                        marginTop: '0.4rem',
                                      }}
                                    />
                                    Add/Reply Comment
                                  </Link>
                                </Stack>
                              )}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Stack>
                )}

                {value?.comments?.length === 0 &&
                  (showCommentsInput === value?.id ||
                    activeChecklistId[value?.id]) && (
                    <Stack
                      component="form"
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      onSubmit={handleSubmit((data) => onSubmit(data, value))}
                      sx={{ padding: '8px 12px 16px' }}
                    >
                      <ControlledTextField
                        name={`comment-${value?.user}`}
                        control={control}
                        fullWidth
                        className={
                          showCommentsInput === value?.id && !openAssignDialog
                            ? 'blink-border-animation'
                            : ''
                        }
                        sx={{
                          background: '#FFF7FA',
                          borderRadius: '6px',
                          border:
                            showCommentsInput === value?.id && !openAssignDialog
                              ? '2px solid transparent'
                              : '',
                        }}
                      />
                      <NameAvatar
                        firstName={user_data?.first_name}
                        lastName={user_data?.last_name}
                        avatarStyle={{ width: '32px', height: '32px' }}
                      />
                    </Stack>
                  )}
              </Stack>
            )
          );
        })}
      </List>
      {openDrawer && (
        <SupportDocsDrawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          supportDocs={supportDocList}
          isExternal={isExternal}
        />
      )}
    </React.Fragment>
  );
};

export default Checklist;
