import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Badge, IconButton, Link, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import NotificationDrawer from './NotificationDrawer';
import { useUserData } from '../../../App/Component/UserDataProvider';
import { fetchNotifications } from '../../../Services/Notification';

const NotificationComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [updatedNotification, setUpdatedNotification] = useState<any[]>([]);
  const [currentNotification, setCurrentNotification] = useState<any>({});

  const { user_id } = useUserData();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const parseMessage = (message: string) => {
    const regex = /(.*)<a href='(.*?)'>(.*?)<\/a>(.*)/;
    const match = message.match(regex);

    if (match) {
      const beforeLink = match[1]?.trim();
      const href = match[2];
      const linkText = match[3];
      const afterLink = match[4]?.trim();

      return {
        beforeLink,
        href,
        linkText,
        afterLink,
      };
    }

    const beforeLink = message;

    return { beforeLink };
  };

  useEffect(() => {
    let ws = new WebSocket(
      process.env.REACT_APP_RIVERUS_CLM_WS_API +
        `ws/notification/?token=${user_id}`
    );

    ws.onclose = () => {
      ws = new WebSocket(
        process.env.REACT_APP_RIVERUS_CLM_WS_API +
          `ws/notification/?token=${user_id}`
      );
    };

    ws.onmessage = (event) => {
      const notifications: any = JSON.parse(event.data);
      if (notifications?.notification_id) {
        enqueueSnackbar(`${parseMessage(notifications?.message)?.beforeLink}`, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 12000,
          content: (key) => (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                padding: '10px',
                background: '#fff4e5',
              }}
            >
              <NotificationsIcon sx={{ color: '#ed6c02', marginRight: 8 }} />
              <Stack>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: '#663c00' }}
                >
                  Notification
                </Typography>

                {parseMessage(notifications?.message) && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#663c00',
                      cursor: notifications?.is_read ? 'default' : 'pointer',
                    }}
                  >
                    {parseMessage(notifications?.message)?.beforeLink}{' '}
                    <Link
                      href={notifications?.link}
                      target="_blank"
                      variant="body2"
                      color="inherit"
                    >
                      {parseMessage(notifications?.message)?.linkText}
                    </Link>{' '}
                    {parseMessage(notifications?.message)?.afterLink}
                  </Typography>
                )}
              </Stack>
              <IconButton onClick={() => closeSnackbar(key)}>
                <CloseIcon sx={{ color: '#663c00' }} />
              </IconButton>
            </Stack>
          ),
        });
      }
      setCurrentNotification(notifications);
      setUpdatedNotification((prev) => [...prev, notifications]);
    };

    // Cleanup the WebSocket connection on component unmount
    return () => {
      ws.close();
    };
  }, []);

  const { data: notification } = useQuery({
    queryKey: ['notification_unread'],
    queryFn: async () => await fetchNotifications(),
    select: (response: any) => response?.unread,
  });

  return (
    <React.Fragment>
      <IconButton onClick={() => setIsOpen(true)}>
        <Badge
          badgeContent={currentNotification?.unread || notification || 0}
          color="primary"
        >
          <NotificationsNoneIcon sx={{ color: '#88305F' }} />
        </Badge>
      </IconButton>
      {isOpen && (
        <NotificationDrawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          updatedNotification={updatedNotification}
        />
      )}
    </React.Fragment>
  );
};

export default NotificationComponent;
