import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserData } from '../App/Component/UserDataProvider';

const Admin = () => {
  const navigate = useNavigate();

  const { userIsAdmin } = useUserData();

  useEffect(() => {
    if (userIsAdmin) {
      navigate('/admin/data-dictionary');
    } else {
      navigate('/documentlibrary');
    }
  }, []);

  return <div></div>;
};

export default Admin;
