import React, { useMemo } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import packageJson from '../../../package.json';
import { getLocalStorage } from '../../Authentication/Actions/authentication';

import './footer.scss';

interface IProps {
  isExternal?: boolean;
}

export const Footer: React.FC<IProps> = ({ isExternal }) => {
  const orgName = getLocalStorage('kcRealm');
  const currentYear = new Date().getFullYear();

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamsTypeValue = queryParams.get('realm');

  return (
    <Box
      className="footer-wrapper"
      sx={{ marginTop: isExternal ? '2rem' : '' }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography color="#959595">
          © {currentYear} Riverus Technology Solutions Pvt. Ltd. All rights
          reserved
        </Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography className="footer-chip">
            {isExternal ? queryParamsTypeValue : orgName}
          </Typography>
          <Typography className="footer-chip">{packageJson.version}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
