import React, { useEffect, useState } from 'react';

import { Box, Button, FormControlLabel, Stack, Switch } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { useUserData } from '../../../App/Component/UserDataProvider';
import { getKeyCloakRealmFromLS } from '../../../Authentication/Actions/authentication';
import DrawerComponent from '../../../RiverusUI/Components/DrawerComponent';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import {
  getAllFilterInsights,
  updatedFilterInsights,
} from '../../../Services/DocumentLibrary';
import { ClauseNameMap } from '../ClauseComponent/Components/ClauseComponent';

interface Props {
  contractData: any;
  open: boolean;
  onClose: VoidFunction;
}

const InsightFilterDrawer: React.FC<Props> = ({
  contractData,
  open,
  onClose,
}) => {
  const [switchValues, setSwitchValues] = useState<any>({});

  const { user_id } = useUserData();
  const realm_name = getKeyCloakRealmFromLS();
  const queryClient = useQueryClient();

  const { data: filterInsightList, isLoading: filterListLoading } = useQuery({
    queryKey: ['get_all_filtered_insights', user_id, contractData?.id],
    queryFn: async () => {
      const response = await getAllFilterInsights(contractData?.id, user_id);
      return response;
    },
    enabled: !!(contractData?.id && user_id),
  });

  useEffect(() => {
    if (filterInsightList?.length > 0) {
      const values: any = {};
      filterInsightList?.forEach((filterInsightListItem: any) => {
        if (filterInsightListItem?.nodes) {
          Object.entries(filterInsightListItem?.nodes)?.forEach(
            ([key, value]: any) => {
              values[key] = value;
            }
          );
        }
      });
      setSwitchValues(values);
    }
  }, [filterInsightList]);

  const handleClose = () => {
    setSwitchValues({});
    onClose();
  };

  const { mutate: updateFilterInsights } = useMutation({
    mutationKey: ['update_filter_insights'],
    mutationFn: async (payload: any) => updatedFilterInsights(payload),
    onSuccess: () => {
      enqueueSnackbar('filter insights updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_all_filtered_insights', user_id, contractData?.id],
      });
      handleClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update filter insights!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleUpdateFilter = () => {
    const payload = {
      id: filterInsightList?.[0]?.id,
      body: {
        id: filterInsightList?.[0]?.id,
        realm: realm_name,
        nodes: switchValues,
        user: user_id,
        contract: contractData?.id,
      },
    };
    updateFilterInsights(payload);
  };

  const handleSwitchChange = (name: string, value: boolean) => {
    setSwitchValues((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <DrawerComponent
      open={open}
      onClose={handleClose}
      drawerHeader="Filter Insights"
      renderAction={
        <Button onClick={handleUpdateFilter} variant="contained">
          Save and close
        </Button>
      }
    >
      {filterListLoading ? (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      ) : (
        <Stack width="100%" spacing={1}>
          {Object.entries(switchValues).map(
            ([key, value]: any) =>
              ClauseNameMap[key] && (
                <FormControlLabel
                  key={key}
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) =>
                        handleSwitchChange(key, e.target.checked)
                      }
                    />
                  }
                  label={ClauseNameMap[key]}
                />
              )
          )}
        </Stack>
      )}
    </DrawerComponent>
  );
};

export default InsightFilterDrawer;
