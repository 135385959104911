import React, { useState } from 'react';

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import StarIcon from '@mui/icons-material/Star';
import { Button, Chip, LinearProgress, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';

import { useUserData } from '../../../App/Component/UserDataProvider';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import { draftStatus } from '../../State/DraftState';
import ComparisonDialog from '../ComparisonDialog';

interface Props {
  versionTableData: string[];
  handleSelectionChange: (selection: any) => void;
  loadingTable: boolean;
  draftRowData: any;
}

const tableButtonStyle = {
  padding: 0,
  justifyContent: 'start',
  ':hover': { background: 'transparent' },
};

const chipsStyle = {
  background: riPrimary[20],
  borderRadius: '30px',
  padding: '6px 12px',
};

const VersionTable: React.FC<Props> = ({
  versionTableData,
  handleSelectionChange,
  loadingTable,
  draftRowData,
}) => {
  const [openComparisonDrawer, setOpenComparisonDrawer] = useState(false);
  const [compareFileName, setCompareFileName] = useState<any[]>([]);
  const [selectedDraftsLink, setSelectedDraftsLink] = useState<string[]>([]);

  const { user_id } = useUserData();

  const isOwner = React.useCallback(
    (owners: any) => {
      const index = owners?.findIndex((owner: any) => owner?.id === user_id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [user_id]
  );

  const canAccessDraft = React.useCallback(
    (data: any) => {
      const approvalData = data?.approvers?.filter(
        (item: any) => item?.id === user_id
      );
      const collaboratorData = data?.collaborators?.filter(
        (item: any) => item?.id === user_id
      );
      const signatoryData = data?.signatories?.filter(
        (item: any) => item?.id === user_id
      );
      const preSignatoryData = data?.pre_signatories?.filter(
        (item: any) => item?.id === user_id
      );
      const owner = isOwner(data?.owners);
      const creator = data?.creator?.id === user_id;
      if (
        owner ||
        approvalData?.length ||
        collaboratorData?.length ||
        signatoryData?.length ||
        preSignatoryData?.length ||
        creator
      ) {
        return true;
      }
      return false;
    },
    [user_id, isOwner]
  );

  const handleCompareVersion = async (compareData: any) => {
    const compareVersionData = compareData?.compare_versions?.data;
    setSelectedDraftsLink([
      compareVersionData?.[0]?.id,
      compareVersionData?.[1]?.id,
    ]);
    const createdBY = compareData?.created_by;
    if (createdBY?.id === user_id) {
      const name1 = `V${compareVersionData?.[0]?.version}-${compareVersionData?.[0]?.contractName}`;
      const name2 = `V${compareVersionData?.[1]?.version}-${compareVersionData?.[1]?.contractName}`;
      setOpenComparisonDrawer(true);
      setCompareFileName([name1, name2]);
    } else {
      enqueueSnackbar('You are not assigned any role for this draft!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleContractNameClick = React.useCallback(
    (canAccess: boolean, version: string, id: string, status: string) => {
      if (canAccess) {
        const isDraftUpdated =
          draftRowData?.status === status && draftRowData?.version === version
            ? true
            : false;
        window.open(`/draft/${version}/${id}?isDraftUpdated=${isDraftUpdated}`);
      } else {
        enqueueSnackbar('You are not assigned any role for this draft!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [draftRowData]
  );

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: 'Version',
      minWidth: 165,
      sortable: true,
      sortComparator: (a, b) => {
        // Convert the version values to numbers for proper sorting.
        const versionA = parseFloat(a);
        const versionB = parseFloat(b);
        return versionA - versionB;
      },
      renderCell: (data: any) => {
        const canAccess = canAccessDraft(data.row);
        return (
          <Button
            sx={tableButtonStyle}
            onClick={() => {
              if (!data?.row?.dropUndropDetails) {
                if (data?.row?.compare_versions) {
                  handleCompareVersion(data?.row);
                } else {
                  if (!data?.row?.active_status) {
                    handleContractNameClick(
                      canAccess,
                      data?.row?.version,
                      data?.row?.id,
                      data?.row?.status
                    );
                  } else if (data?.row?.active_status) {
                    enqueueSnackbar('This version is deleted', {
                      variant: 'info',
                      anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    });
                  }
                }
              }
            }}
          >
            {data?.row?.compare_versions?.visible_version
              ? data?.row?.compare_versions?.visible_version
              : `V${data?.row?.version}`}
            {data?.row?.dropUndropDetails && (
              <Chip
                label={data?.row?.dropUndropDetails?.status}
                style={{ padding: 0, marginLeft: '8px' }}
              />
            )}
            {data?.row?.status === draftStatus?.SIGNATURE_ABORTED && (
              <Chip
                label="sign aborted"
                style={{ padding: 0, marginLeft: '8px' }}
              />
            )}
            {!data?.row?.dropUndropDetails && data?.row?.active_status && (
              <Chip label="Deleted" style={{ padding: 0, marginLeft: '8px' }} />
            )}
            {data?.row?.status ===
              draftStatus?.CONTRACT_EXECUTED_SUCCESSFULLY && (
              <Chip
                label="Executed Contract"
                style={{ padding: 0, marginLeft: '8px' }}
              />
            )}
          </Button>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Created/Uploaded by',
      minWidth: 280,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      renderCell: (data: any) => {
        const createdOnDate = new Date(
          data?.row?.dropUndropDetails
            ? data?.row?.dropUndropDetails?.created_on
            : data?.row?.created_on
        )?.toLocaleDateString('en-GB');
        const first_name = data?.row?.dropUndropDetails
          ? data?.row?.dropUndropDetails?.created_by?.first_name
          : data?.row?.created_by?.first_name;
        const last_name = data?.row?.dropUndropDetails
          ? data?.row?.dropUndropDetails?.created_by?.last_name
          : data?.row?.created_by?.last_name;
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            {!data?.row?.dropUndropDetails && data?.row?.version_type && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                textTransform="capitalize"
                style={chipsStyle}
              >
                {data?.row?.version_type}
                {data?.row?.version_type?.toLowerCase() === 'major' && (
                  <StarIcon />
                )}
              </Stack>
            )}
            <Typography fontSize="13px">{createdOnDate}</Typography>
            {data?.row?.version !== 0 && data?.row?.is_internal === false && (
              <AttachEmailIcon />
            )}
            {data?.row?.compare_versions && <CompareOutlinedIcon />}
            <NameAvatar firstName={first_name} lastName={last_name} />
          </Stack>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <DataGrid
        rows={versionTableData}
        columns={columns}
        checkboxSelection
        hideFooterPagination
        style={{ border: 'none' }}
        disableColumnMenu
        hideFooterSelectedRowCount
        onRowSelectionModelChange={handleSelectionChange}
        loading={loadingTable}
        isRowSelectable={(params) => {
          return params?.row?.active_status || params?.row?.dropUndropDetails
            ? false
            : true;
        }}
        slots={{
          loadingOverlay: LinearProgress,
        }}
      />
      {openComparisonDrawer && (
        <ComparisonDialog
          openComparisonDrawer={openComparisonDrawer}
          setComparisonDrawer={setOpenComparisonDrawer}
          draftDetails={draftRowData}
          selectedDraftsLink={selectedDraftsLink}
          onClose={() => {
            setSelectedDraftsLink([]);
          }}
          compareFileName={compareFileName}
        />
      )}
    </React.Fragment>
  );
};

export default VersionTable;
