import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import ActionButton from './ActionButton';
import WithdrawEscalationDialog from './WithdrawEscalationDialog';
import { useUserData } from '../../../App/Component/UserDataProvider';
import { riPrimary } from '../../../RiverusUI/Theme/colors';
import {
  deleteCustomDeviation,
  editDraftData,
  fetchKDPTagDeviations,
} from '../../../Services/Draft';
import {
  droppedMsg,
  handleSeeMoreAndLess,
  highlightedSentence,
} from '../Helper';

interface Props {
  cardData: any;
  instance: any;
  userIsOwner: boolean;
  handleOpenClauseLibraryDrawer: VoidFunction;
  deviationType: string;
  draftId: string;
  isDraftDropped?: boolean;
}

const CustomDeviationCard: React.FC<Props> = ({
  cardData,
  instance,
  userIsOwner,
  handleOpenClauseLibraryDrawer,
  deviationType,
  draftId,
  isDraftDropped,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState<boolean>(false);
  const [deviationId, setDeviationId] = useState<string>('');
  const [showFullText, setShowFullText] = useState<any>({
    id: '',
    show: false,
  });

  const queryClient = useQueryClient();

  const handleCloseDialog = () => {
    setOpenWithdrawDialog(false);
  };

  const handleOpenDialog = (value: string) => {
    setOpenWithdrawDialog(true);
    setDeviationId(value);
  };

  const { mutate: updateDraftData } = useMutation({
    mutationFn: editDraftData,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch_Kdp_deviations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetch_custom_deviations'],
      });
      enqueueSnackbar('Successfully updated draft data!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: removeCustomDeviations, isPending: loadingRemoveIssue } =
    useMutation({
      mutationKey: ['remove_Custom_deviation'],
      mutationFn: (id: string) => {
        return deleteCustomDeviation(id);
      },
      onSuccess: async () => {
        const updatedDeviationsList = await queryClient.fetchQuery({
          queryKey: ['fetch_Kdp_deviations', draftId, deviationType],
          queryFn: async () => {
            const id = deviationType === 'kdp' ? cardData?.draft : draftId;
            const response = await fetchKDPTagDeviations(id, deviationType);
            return response?.results;
          },
        });

        const updatedCustomDeviationList = await queryClient.fetchQuery({
          queryKey: ['fetch_custom_deviations', draftId],
          queryFn: async () => {
            const response = await fetchKDPTagDeviations(draftId, 'custom');
            return response?.results;
          },
        });

        const updatedPolicyApproversIds = updatedDeviationsList
          ?.flatMap(
            (deviation: any) =>
              deviation?.condition?.approvers?.map(
                (approver: any) => approver?.id
              ) || []
          )
          .filter((id: any) => id !== undefined && id !== null);

        const updatedCustomApproversIds = updatedCustomDeviationList
          ?.flatMap(
            (deviation: any) =>
              deviation?.custom_datapoint?.[0]?.approvers || []
          )
          .filter((id: any) => id !== undefined && id !== null);

        const updatedCombinedApproversIds = Array.from(
          new Set([...updatedPolicyApproversIds, ...updatedCustomApproversIds])
        );

        const payload = {
          id: cardData?.draft,
          body: {
            approvers: updatedCombinedApproversIds,
          },
        };
        updateDraftData(payload);
        handleCloseDialog();
      },
    });

  const handleWithdrawEscalation = () => {
    removeCustomDeviations(deviationId);
  };

  const handleToggle = (id: string) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  const { user_id } = useUserData();

  return (
    <>
      {cardData?.custom_datapoint?.map((item: any, index: number) => (
        <Box
          key={index}
          sx={{
            marginTop: '20px',
            padding: '15px',
            background: '#88305F24',
            borderRadius: '10px',
          }}
        >
          <Stack spacing={2}>
            <Typography fontSize="16px" fontWeight={700}>
              Issue created in {cardData?.condition?.condition}
            </Typography>

            <Typography fontSize="14px" fontWeight={700}>
              Text in the draft
            </Typography>
            <Stack
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                padding: '10px',
                background: '#FFF7FA',
                borderRadius: '10px',
              }}
            >
              <Typography fontSize="13px">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    highlightedSentence(item?.selectedValue, instance)
                  }
                >
                  {showFullText?.id === item.id && showFullText?.show
                    ? item?.selectedValue
                    : handleSeeMoreAndLess(item?.selectedValue)}
                  {item?.selectedValue?.split(/\s+/)?.length > 20 && (
                    <span
                      onClick={() => handleToggle(item.id)}
                      style={{ cursor: 'pointer', color: riPrimary?.[500] }}
                    >
                      {showFullText?.id === item.id && showFullText?.show
                        ? ' See less'
                        : ' See more'}
                    </span>
                  )}
                </span>
              </Typography>
              {userIsOwner && !isDraftDropped && (
                <ActionButton
                  sentence={item?.selectedValue}
                  instance={instance}
                  handleOpenClauseLibraryDrawer={handleOpenClauseLibraryDrawer}
                  cardData={cardData}
                  fieldValueId={item.id}
                />
              )}
            </Stack>
            <Typography fontSize="14px" fontWeight={700}>
              Reason (context)
            </Typography>
            <Box
              sx={{
                padding: '10px',
                background: '#FFF7FA',
                borderRadius: '10px',
              }}
            >
              <Typography fontSize="13px">{item?.reason}</Typography>
            </Box>
            <Stack direction="row" justifyContent="end">
              {(cardData?.approval_status === 'approval_pending' ||
                !cardData?.approval_status) && (
                <Tooltip
                  title={droppedMsg}
                  arrow
                  disableHoverListener={!isDraftDropped}
                >
                  <Stack>
                    <Button
                      style={{
                        padding: '5px 8px',
                      }}
                      variant="outlined"
                      disabled={
                        (!userIsOwner && cardData?.created_by !== user_id) ||
                        isDraftDropped
                      }
                      onClick={() => handleOpenDialog(cardData?.id)}
                    >
                      Withdraw escalation
                    </Button>
                  </Stack>
                </Tooltip>
              )}
              {cardData?.approval_status === 'approved' && (
                <Chip
                  label="Approved"
                  icon={<CheckIcon />}
                  style={{ padding: '15px 5px' }}
                />
              )}
            </Stack>
          </Stack>
        </Box>
      ))}
      <WithdrawEscalationDialog
        open={openWithdrawDialog}
        onClose={handleCloseDialog}
        handleWithdrawEscalation={handleWithdrawEscalation}
        loadingRemoveIssue={loadingRemoveIssue}
      />
    </>
  );
};

export default CustomDeviationCard;
